<template>
    <vue-loading
      :active="true"
      :can-cancel="false"
      :is-full-page="true"
      color="rgba(255, 0, 0, 0.75)"
      :width="125"
      :height="125"
      :opacity="1"
      backgroundColor="rgba(255, 255, 255, 0.5)"
    ></vue-loading>
</template>

<script>
import VueLoading from 'vue-loading-overlay'

export default {
  name: 'CLoading',
  components: { VueLoading }
}
</script>

<style lang="scss" scoped>
</style>
