<template>
  <OButton
    :to="{ name: `new-${type}`, params: { target } }"
    theme="primary"
    type="button"
  >
    New message
  </OButton>
</template>

<script>
import OButton from '@/objects/OButton'

export default {
  name: 'CNewConversationButton',
  components: {
    OButton
  },
  props: {
    target: {
      type: String
    },
    type: {
      type: String,
      default: 'conversation'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
